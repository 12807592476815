<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Заказы</div>
                <div class="page__desc">Список заказов</div>
            </div>
        </div>

        <div v-if="orders != null"
             class="page__content">

            <paginate
                    :page-count="orders.pageLast"
                    :click-handler="paginator"
                    v-model="page"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"/>

            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>ID</th>
                        <th>Статус</th>
                        <th>Время заказа</th>
                        <th>Время доставки</th>
                        <th>Стоимость</th>
                    </tr>
                    <tbody v-if="!this.$store.state.loading.showLoading">
                        <tr v-for="order in orders.items" v-bind:key="order.id">
                            <td>
                                <router-link
                                        :to="{ name: 'DeliveryOrdersUpdate', params: {id: order.id } }">#{{ order.id }}</router-link>
                            </td>
                            <td>{{ order.status }}</td>
                            <td>{{ order.confirmed_at | moment("DD MMM YYYY HH:mm") }}</td>
                            <td>{{ order.slot_from | moment("DD MMM YYYY HH:mm") }} - {{ order.slot_to | moment("HH:mm") }}</td>
                            <td>
                                <template v-if="order.price_special !== order.price_normal">{{ order.price_special }} ЛВ <s>{{ order.price_normal }} ЛВ</s></template>
                                <template v-else>{{  order.price_normal }} ЛВ</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import paginate from 'vuejs-paginate'
    import router from '@/router'

    export default {
        name: 'DeliveryOrders',
        components: {
            paginate,
        },
        data() {
            return {
                page: this.$route.query.page != null ? parseInt(this.$route.query.page) : 1
            }
        },
        computed: {
            orders() {
                return this.$store.state.orders.orders
            }
        },
        methods: {
            ...mapActions([
                'deliveryOrders'
            ]),
            async getOrders() {
                await this.deliveryOrders({
                    page: this.page,
                    limit: 10
                })
            },
            paginator(pageNum) {
                router.push({ name: 'DeliveryOrders', query: { page: pageNum }});
            }
        },
        created() {
            this.getOrders();
        },
        watch: {
            $route() {
                this.getOrders();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>